<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">单位更改日志</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="姓名/手机号" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
				</div>
				<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
				</el-button>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" label="姓名">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.real_name}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="手机号" width="130">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.mobile}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="修改单位">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.first_unit_name}}</span>
						<template v-if="scope.row.second_unit_name != ''">
							<span style="font-weight: 800;">- {{scope.row.second_unit_name}}</span>
						</template>
						<template v-if="scope.row.third_unit_name != ''">
							<span style="font-weight: 800;">- {{scope.row.third_unit_name}}</span>
						</template>
						----->
						<span style="font-weight: 800;">{{scope.row.first_unit_name1}}</span>
						<template v-if="scope.row.second_unit_name1 != ''">
							<span style="font-weight: 800;">- {{scope.row.second_unit_name1}}</span>
						</template>
						<template v-if="scope.row.third_unit_name1 != ''">
							<span style="font-weight: 800;">- {{scope.row.third_unit_name1}}</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="修改时间" width="190">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				search: {
					s: 'store/unit_user/editlog',
					keyword: '',
					page: 1,
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>

<style>

</style>
